<template>
<main
  v-if="$can('verListadosConDetalleOrganizaciones')"
>
  <div class="text-center">
    <ToggleNavigation :items="items" :type="'art'"></ToggleNavigation>
   <v-alert dense prominent shaped type="success" v-model="showAlertExcel"
      >Excel generado con exito</v-alert
    >
    <v-card>
      <ComponentModalActivityLog
        v-on:handleClose="dialogSeeActivityLog = false;"
        :modal="dialogSeeActivityLog"
        type=""
        process="asociaciones"
        :consecutive="consecutive"
        v-if="dialogSeeActivityLog"
      ></ComponentModalActivityLog>
      <v-card-title>
        Organizaciones
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
        <v-dialog v-model="dialog"  width="800">
      <template v-slot:activator="{ on, attrs }">
        <export-excel
          :data="allOrganizations"
          :fields="jsonFields"
          :before-generate="startDownload"
          :before-finish="finishDownload"
          worksheet="Organizaciones"
          name="Organizaciones.xls"
          style="ml-4 margin-bottom:10px"
        >
          <v-btn
            color="green darken-4 ml-4 white--text"
            small
            dark
            class="mb-2"
          >
            <v-icon small class="mr-2">fa fa-file-excel</v-icon>
            Exportar
          </v-btn>
        </export-excel>
        <v-btn
          v-if="$can('crearOrganizaciones')"
          color="indigo darken-4 white--text"
          dark
          small
          class="mb-2 ml-3"
          v-bind="attrs"
          v-on="on"
        >
         <v-icon left small>fa-plus</v-icon>Crear Organización
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline indigo darken-4 white--text">
          Datos Asociación
        </v-card-title>
        <v-card-text>
          <v-form >
            <v-container>
              <v-row>
                <v-col
                    class="d-flex"
                    cols="12"
                    sm="12"
                    lg="12"
                    md="12"
                  >
                  <v-select
                    :items="locations"
                    item-text="nombre"
                    item-value="id"
                    single
                    v-model="organization.location_id"
                    label="localizacion"
                  ></v-select>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    lg="12"
                    md="12"
                  >
                  <v-text-field
                    v-model="organization.name"
                    label="Nombre"
                    required
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  sm="4"
                  lg="4"
                  md="4"
                >
                  <v-text-field
                    v-model="organization.abbr_name"
                    label="Nombre Abreviado"
                    required
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  sm="4"
                  lg="4"
                  md="4"
                >
                  <v-text-field
                    v-model="organization.nit"
                    label="Nit"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  sm="4"
                  lg="4"
                  md="4"
                >
                  <v-text-field
                    v-model="organization.cod_nit"
                    label="Digíto de verificación"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  sm="4"
                  lg="4"
                  md="4"
                >
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="organization.constitution_year"
                        label="Año de constitucion"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="organization.constitution_year"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                
                <v-col
                  cols="12"
                  sm="8"
                  lg="8"
                  md="8"
                >
                  <v-text-field
                    v-model="organization.legal_representative"
                    label="Representante Legal"
                    required
                  ></v-text-field>
                </v-col>
                 <v-col
                  cols="12"
                  sm="4"
                  lg="4"
                  md="4"
                >
                 <v-select
                    :items="types_documents"
                    label="Tipo Doc."
                    item-value="item"
                    item-text="item"
                    single
                    v-model="organization.document_type"
                  ></v-select>
                  </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="organization.representative_document_number"
                    label="Documento Representante Legal"
                    required
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="8"
                  lg="8"
                  md="8"
                >
                  <v-text-field
                    v-model="organization.address"
                    label="Direccion"
                    required
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  lg="4"
                  md="4"
                >
                  <v-text-field
                    v-model="organization.telephone"
                    label="Telefono"
                    required
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  lg="4"
                  md="4"
                >
                  <v-text-field
                    v-model="organization.email"
                    label="email"
                    :rules="[rules.required, rules.email]"
                    required
                  ></v-text-field>
                </v-col>
                <v-col
                  class="d-flex"
                  cols="12"
                  sm="4"
                  lg="4"
                  md="4"
                  >
                  <v-select
                    :items="productive_systems"
                    label="Sistema Productivo"
                    item-value="id"
                    item-text="name"
                    single
                    v-model="organization.productive_system_id"
                  ></v-select>
                </v-col>
                <v-col
                  class="d-flex"
                  cols="6"
                  sm="4"
                  lg="4"
                  md="4"
                  >
                  <v-select
                    :items="types_organizations"
                    label="Tipo de Organización"
                    single
                    v-model="organization.type_of_organization"
                  ></v-select>
                </v-col>
                <v-col
                  class="d-flex"
                  cols="6"
                  sm="4"
                  lg="4"
                  md="4"
                  >
                  <v-select
                    :items="entities"
                    label="Entidad sin animo de lucro"
                    single
                    v-model="organization.ESAL"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
            
            
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              dialog = false;
            "
            >Cerrar</v-btn
          >
          <v-btn color="blue darken-1" text @click="save"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
      </v-card-title>
     
      <v-data-table
        :footer-props="{
          'items-per-page-options': [5,10,15]
        }"
        :headers="headers"
        :items="allOrganizations"
        :search="search"
        item-key="id"
        
      >
      <template v-slot:item.fishermen_count="{item}">
        <button @click="showFishermen(item.id)">{{item.fishermen_count}}</button>
      </template>
      <template v-slot:item.action="{ item }">
          <v-menu left bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-if="$can('editarOrganizaciones')"
               @click="showOrganization(item.id)">
                <v-list-item-title>
                  <v-icon class="mr-2" small>mdi-circle-edit-outline</v-icon> Editar
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="InfoOrganization(item.id)">
                <v-list-item-title>
                  <v-icon class="mr-2" small>mdi-eye</v-icon> Información
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="uploadSupports(item.id)">
                <v-list-item-title>
                  <v-icon class="mr-2" small>mdi-file-upload</v-icon> Soportes
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="showFishermen(item.id)">
                <v-list-item-title>
                  <v-icon class="mr-2" small>mdi-eye</v-icon> Pescadores
                </v-list-item-title>
              </v-list-item>
               <v-list-item @click="seeActivityLog(item.id)"> 
                <v-list-item-title>
                  <v-icon class="mr-2" small>fas fa-history</v-icon>
                 Historial de actividades
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
  <support-documents ref="uploadSupportComponent"></support-documents>
  <show-info-organization ref="ShowInfoOrganizationComponent"></show-info-organization>
  </main>
</template>
<script>
import swal from "sweetalert";
import { getLocationsAll,} from "../../services/LocationsService";
import { getProductiveSystemAll,} from "../../services/ProductiveSystemService";
import { saveOrganizations,showOrganizationById,updateOrganizations} from "../../services/OrganizationService";
import SupportDocuments from './SupportDocuments';
import ShowInfoOrganization from './ShowInfoOrganization';
import {mapGetters, mapActions} from 'vuex';
import ComponentModalActivityLog from "../../components/ComponentModalActivityLog";
import organizationsField from "../../utils/organizationsFields";
import ToggleNavigation from "../../components/ToggleNavigation";
import { navegacion } from "../../services/exportNavigation";

const DEFAULT_PER_PAGE = 10;
export default {
  components: { SupportDocuments,ShowInfoOrganization, ComponentModalActivityLog,ToggleNavigation },
  data(){
        return{ 
          items: [],
          showAlertExcel: false,
          menu: false,
          modal: false,
          menu2: false,
          dialog: false,
          consecutive: '',
          dialogSeeActivityLog: false,
          locations:[],
          productive_systems:[],
          types_documents:['CC','CE','OTHER'],
          types_organizations:['Federacion','Asociacion','Cooperativa','Fundacion'],
          entities:['SI','NO'],
          form_mode: 'store',
          organization:{
            id: '',
            name:'',
            location_id:'',
            abbr_name:'',
            nit:'',
            constitution_year:'',
            legal_representative:'',
            document_type:'',
            representative_document_number:'',
            address:'',
            telephone:'',
            email:'',
            productive_system_id:'',
            type_of_organization:'',
            ESAL:'',
          },
          search: '',
          headers: [
            {text: 'Localización',align: 'start',sortable: false,value: 'location.nombre', },
            { text: 'Nombre abreviado', value: 'abbr_name' },
            { text: 'Nit', value: 'nit' },
            { text: 'Representante Legal', value: 'legal_representative' },
            { text: 'No. pescadores vigentes', value: 'fishermen_count' },
            { text: "Acción", value: "action", width: "25%" },
          ],
          currentItemsPerPage: DEFAULT_PER_PAGE,
          jsonFields: organizationsField,
          rules: {
            required: value => !!value || 'Required.',
            counter: value => value.length <= 20 || 'Max 20 characters',
            email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              return pattern.test(value) || 'Invalid e-mail.'
            },
          }
          
        }
    },
    computed: {
      ...mapGetters(["allOrganizations"]),
    },
    methods:{
      showFishermen(organizationId){
        this.$router.push(`/fishermen-art/${organizationId}`);
      },
        seeActivityLog: async function (consecutive) {
          this.consecutive = consecutive;
          this.dialogSeeActivityLog = true;
        },
        ...mapActions(["getOrganizations"]),
        async save(){
          try {
            
            if(this.form_mode==='store'){
              this.$store.commit("LOADER", true);
              //this.$store.commit("START_LOADER");
              await saveOrganizations(this.organization);
              //this.$store.commit("LOADER", false);
              this.$store.commit("STOP_LOADER");
              await swal("Exito!", "Datos guardados!", "success");
              this.getOrganizations();
              this.form_mode = 'store';
              this.cleanData();
            }else if(this.form_mode==='update'){
              this.$store.commit("LOADER", true);
              //this.$store.commit("START_LOADER");
              await updateOrganizations(this.organization);
              this.$store.commit("LOADER", false);
              //this.$store.commit("STOP_LOADER");
              await swal("Exito!", "Datos actualizados!", "success");
              this.getOrganizations();
              this.form_mode = 'store';
              this.dialog=false;
              this.cleanData();
            }
          } catch (err) {
            //this.$store.commit("LOADER", false);
            this.$store.commit("STOP_LOADER");
            if (err.response.status == 422) {
              const obj_erros = Object.values(err.response.data.errors);
              obj_erros.forEach((err) => {
                this.$toasted.show(`Error ${Array.isArray(err) ? err[0] : err}`, {
                  theme: "bubble",
                  position: "bottom-right",
                  duration: 4000,
                  action: {
                    text: "",
                    onClick: (e, toastObject) => {
                      toastObject.goAway(0);
                    },
                  },
                });
              });
            }
          }
        },
        async getLocations(){
            const response = await getLocationsAll();
            this.locations = response.data;
        },
        async getProductiveSystem(){
            const response = await getProductiveSystemAll();
            this.productive_systems = response.data;
        },
        async showOrganization(id){

          this.form_mode='update';
          
          const response = await showOrganizationById(id);
          
          this.organization.location_id = response.data.location_id;
          this.organization.name = response.data.name;
          this.organization.abbr_name = response.data.abbr_name;
          this.organization.nit = response.data.nit;
          this.organization.cod_nit = response.data.cod_nit;
          this.organization.constitution_year = response.data.constitution_year;
          this.organization.legal_representative = response.data.legal_representative;
          this.organization.document_type = response.data.id_type;
          this.organization.representative_document_number = response.data.identification_number;
          this.organization.address = response.data.address;
          this.organization.email = response.data.email;
          this.organization.telephone = response.data.phone;
          this.organization.productive_system_id = response.data.productive_system_id;
          this.organization.type_of_organization = response.data.type_of_organization;
          this.organization.id = response.data.id;
          this.organization.ESAL = response.data.ESAL;
          this.dialog=true;

        },
        async uploadSupports(id){
          this.$refs.uploadSupportComponent.openDialog(id);
        },
        async InfoOrganization(id){
          this.$refs.ShowInfoOrganizationComponent.openDialog(id);
        },
        cleanData(){
          this.organization={};
        },
        async exportCompanies(){
          const data = (await this.getOrganizations({
            page: 1, itemsPerPage: this.currentItemsPerPage,
          }))?.data

          return {
            data : data
          };
        },
        startDownload() {
          //this.currentItemsPerPage = DEFAULT_PER_PAGE;
          this.$store.commit("LOADER", true);
          //this.$store.commit("START_LOADER");
        },
        finishDownload() {
          //this.$store.commit("LOADER", false);
          this.$store.commit("STOP_LOADER");
          this.showAlertExcel = true;
          setTimeout(() => {
            this.showAlertExcel = false;
          }, 2000);
          //this.currentItemsPerPage = DEFAULT_PER_PAGE;
        },
    },
    async created(){
        this.items = navegacion.items_navegation;
        this.getLocations();
        this.getProductiveSystem();
        await this.getOrganizations();
    }
}
</script>
