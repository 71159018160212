<template>
  <v-dialog max-width="600px" v-model="dialog">
    <v-card>
      <v-card-title class="headline indigo darken-4 white--text">
        <span class="text-h5">
          <v-icon left color="white">mdi-folder</v-icon>
          Soportes
        </span>
      </v-card-title>
      <v-card-text>
        <v-row v-if="supports.length" class="pt-2">
          <v-col cols="12" class="supports-container mt-2">
            <v-list>
              <v-list-group
                v-for="(supportType, index) in supportTypesWithSupports"
                :key="`support_type_${index}`"
              >
                <template v-slot:activator>
                  <v-list-item-title>{{ supportType.name }}</v-list-item-title>
                </template>
                <div v-for="(support, index) in getSupportByType(supportType.code)" :key="index">
                <v-list-item
                  :key="`support_${index}_${support.id}`"
                  class="mt-1"
                >
                  <v-list-item-title>
                    {{support.name}}
                    <v-chip v-if="support.date" small>{{ support.date | formatDate }}</v-chip>
                    <small class="d-block text-caption font-italic">{{ support.created_at | formatAgo }}</small>
                  </v-list-item-title>
                  <v-spacer></v-spacer>
                  <v-btn icon  @click="viewSupport(support.id)">
                    <v-icon small color="white--text">mdi-file-eye-outline</v-icon>
                  </v-btn >
                  <v-btn icon @click="downloadSupport(support.id, support.type.code)">
                    <v-icon small color="white--text">mdi-download</v-icon>
                  </v-btn >
                  <v-btn icon @click="deleteSupport(support.id)">
                    <v-icon small color="white--text">mdi-delete</v-icon>
                  </v-btn >
                </v-list-item>
                  <v-divider v-if="index < getSupportByType(supportType.code).length-1"></v-divider>
                </div>
              </v-list-group>
            </v-list>
          </v-col>
          <v-divider></v-divider>
        </v-row>
        
        <v-row>
          <v-col>
            <span class="text-h5">
              <v-icon class="mr-2" small>mdi-file-upload</v-icon>
              Nuevo Soporte
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" lg="12" md="12">
            <v-select
              label="Tipo de soporte"
              :items="support_types"
              item-text="name"
              item-value="id"
              single
              v-model="type"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row v-if="typeReportDate">
          <v-col cols="12" sm="12" lg="12" md="12">
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Fecha"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" lg="12" md="12">
            <v-file-input
              accept="application/pdf"
              v-model="file"
              label="Soporte"
            ></v-file-input>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">Cerrar</v-btn>
        <v-btn color="blue darken-1" text @click="saveSupport">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  downloadSupport,
  getSupportTypes,
  supportList,
  uploadSupport,
  deleteSupport,
  viewSupport,
} from "@/services/OrganizationService";
import swal from "sweetalert";

export default {
  name: "SupportDocuments.vue",
  data() {
    return {
      menu2: false,
      dialog: false,
      file: "",
      type: "",
      date: "",
      report_date: "",
      organization_id: "",
      support_types: [],
      supports: [],
    };
  },
  async created() {
    const response = await getSupportTypes();
    this.support_types = response.data;
  },
  methods: {
    async openDialog(organization_id) {
      this.$store.commit("LOADER", true);
      //this.$store.commit("START_LOADER");
      this.dialog = true;
      this.organization_id = organization_id;
      await this.getSupports();
      this.$store.commit("LOADER", false);
      //this.$store.commit("STOP_LOADER");
    },
    async saveSupport() {
      try {
        this.$store.commit("LOADER", true);
        //this.$store.commit("START_LOADER");
        const formData = new FormData();
        if (this.file !== "") {
          formData.append("file", this.file, this.file.name);
        }
        formData.append("organization_id", this.organization_id);
        formData.append("type", this.type);
        formData.append("date", this.date);
        formData.append("report_date", this.typeReportDate);
        await uploadSupport(formData);
        this.file = "";
        this.type = "";
        this.date = "";
        await this.getSupports();
        //this.$store.commit("LOADER", false);
        this.$store.commit("STOP_LOADER");
        await swal("Exito!", "Datos actualizados!", "success");
      } catch (err) {
        //this.$store.commit("LOADER", false);
        this.$store.commit("STOP_LOADER");
        if (err.response.status == 422) {
          const obj_erros = Object.values(err.response.data.errors);
          obj_erros.forEach((err) => {
            this.$toasted.show(`Error ${Array.isArray(err) ? err[0] : err}`, {
              theme: "bubble",
              position: "bottom-right",
              duration: 4000,
              action: {
                text: "",
                onClick: (e, toastObject) => {
                  toastObject.goAway(0);
                },
              },
            });
          });
        }
      }
    },
    async getSupports() {
      const response = await supportList(this.organization_id);
      this.supports = response.data;
    },
    getSupportByType(type) {
      return this.supports.filter((x) => x.type.code === type);
    },
    async downloadSupport(id, name) {
      this.$store.commit("LOADER", true);
      //this.$store.commit("START_LOADER");
      await downloadSupport(id, name);
      this.$store.commit("LOADER", false);
      //this.$store.commit("STOP_LOADER");
    },
    async deleteSupport(id) {
      const value = await swal("Seguro desea continuar", {
        buttons: {
          cancel: "Cancelar",
          Si: true,
        },
      });
      if (value === "Si") {
        this.$store.commit("LOADER", true);
        //this.$store.commit("START_LOADER");
        await deleteSupport(id);
        this.$store.commit("LOADER", false);
        //this.$store.commit("STOP_LOADER");
        this.getSupports();
      } else {
        return false;
      }
    },
    async viewSupport(id) {
      this.$store.commit("LOADER", true);
      //this.$store.commit("START_LOADER");
      await viewSupport(id);
      this.$store.commit("LOADER", false);
     // this.$store.commit("STOP_LOADER");
    },
  },
  computed: {
    typeReportDate() {
      const tipo = this.support_types.find((x) => x.id === this.type);
      return tipo === undefined ? false : Boolean(parseInt(tipo.report_date));
    },
    supportTypesWithSupports(){
      const support_types = this.supports.map((x) => {
        return x.type.id
      });
      return this.support_types.filter((x) => support_types.includes(x.id));
    }
  },
};
</script>

<style scoped>
.supports-container {
  max-height: 300px;
  overflow-y: scroll;
}
</style>
