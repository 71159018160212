export default {
    Nombre: "name",
    'Nombre Abreviado': "abbr_name",
    NIT: "nit",
    "Año de Constitución": "constitution_year",
    "Representante Legal": "legal_representative",
    "Tipo de Documento": "id_type",
    "N° de Identificación": "identification_number",
    Direccion: "address",
    Telefono: "phone",
    "Tipo de organización": "type_of_organization",
    "Entidad sin animo de lucro": "ESAL",
    "No. pescadores vigentes": "fishermen_count",
    "Localizacion": {
        field: "location",
        callback: (location) => {
            if (location) {
                return location.nombre;
            }
        },
    },
    "Sistema Productivo": {
        field: "productive_system",
        callback: (productive_system) => {
            if (productive_system) {
                return productive_system.name;
            }
        },
    },
};