<template>
  <v-dialog max-width="600px" v-model="dialog">
    <v-card>
      <v-card-title class="headline indigo darken-4 white--text">
          <span class="text-h5">
          <v-icon left color="white">mdi-factory</v-icon>
          Asociación
          </span>
      </v-card-title>
      <v-card-text>
          <v-subheader class="mt-2">
              <v-icon left class="red--text"> mdi-information </v-icon>
              <span class="text-h6">Datos Básicos</span>
          </v-subheader>
          <v-divider></v-divider>
          <v-row >                
              <v-col cols="12" lg="12" ><span class="black--text">Localización:</span> <span v-if="organization.location">{{organization.location.nombre}}</span></v-col>
              <v-col cols="12" lg="12"><span class="black--text">Nombre:</span> {{organization.name}}</v-col>
              <v-col cols="12" lg="12"><span class="black--text">Nombre abreviado:</span> {{organization.abbr_name}}</v-col>
              <v-col cols="12" lg="12"><span class="black--text">Nit: </span>{{organization.nit}}</v-col>
              <v-col cols="12" lg="12"><span class="black--text">Año de constitución: </span> {{organization.constitution_year}}</v-col>
              <v-col cols="12" lg="12" ><span class="black--text" >Sistema Productivo: </span> <span v-if="organization.productive_system">{{organization.productive_system.name}}</span></v-col>
              <v-col cols="12" lg="12"><span class="black--text">Tipo de Organización: </span> {{organization.type_of_organization}}</v-col>
              <v-col cols="12" lg="12"><span class="black--text">Entidad sin ánimo de lucro: </span> {{organization.ESAL}}</v-col>
              <v-col cols="12" lg="12"><span class="black--text">No. pescadores vigentes: </span> {{organization.fishermen_count}}</v-col>
          </v-row>
          <v-subheader>
              <v-icon left class="red--text"> mdi-account-circle </v-icon>
              <span class="text-h6">Datos Representante legal</span>
          </v-subheader>
          <v-divider></v-divider>
          <v-row >                
              <v-col cols="12" lg="12"><span class="black--text">Representante legal: </span> {{organization.legal_representative}}</v-col>
              <v-col cols="12" lg="12"><span class="black--text">Tipo Doc. Representante legal:</span> {{organization.id_type}}</v-col>
              <v-col cols="12" lg="12"><span class="black--text">No. Doc. Representante legal:</span> {{organization.identification_number}}</v-col>
          </v-row>
          <v-subheader>
              <v-icon left class="red--text"> mdi-card-account-phone </v-icon>
              <span class="text-h6">Datos de Contacto</span>
          </v-subheader>
          <v-divider></v-divider>
          <v-row >                
              <v-col cols="12" lg="12"><span class="black--text">Dirección: </span> {{organization.address}}</v-col>
              <v-col cols="12" lg="12"><span class="black--text">Teléfono: </span> {{organization.phone}}</v-col>
              <v-col cols="12" lg="12"><span class="black--text">Email:</span> {{organization.email}}</v-col>
          </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          color="blue darken-1"
          text
          @click="dialog=false"
          >Cerrar</v-btn
          >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import {showOrganizationById} from "../../services/OrganizationService";


export default {
  name: "ShowInfoOrganization.vue",
  data(){
    return {
      dialog : false,
      organization_id : '',
      organization : [],
    }
  },
  
  methods : {
    async openDialog(organization_id){
      this.$store.commit("LOADER", true);
      //this.$store.commit("START_LOADER");
      this.dialog = true;
      this.organization_id = organization_id;
      await this.getOrganization();
      this.$store.commit("LOADER", false);
      //this.$store.commit("STOP_LOADER");
    },
   
    async getOrganization(){
      const response = await showOrganizationById(this.organization_id);
      this.organization = response.data;
    },
    
  }
};
</script>

<style scoped>

</style>